import { Link } from 'react-router-dom';

export default function Sidebar() {
  return (
    <>
      <nav className='md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white relative md:w-64 z-10 py-4 px-6'>
        <div className='md:items-stretch md:min-h-full px-0 w-full mx-auto'>
          <div className='md:block text-left md:pb-2 text-gray-700 inline-block whitespace-no-wrap text-sm uppercase font-bold pb-4'>
            Partner Portal
          </div>
          {/* Divider */}
          <hr className='my-4 md:min-w-full' />

          {/* Navigation */}
          <ul className='md:flex-col md:min-w-full flex flex-col list-none md:mb-4'>
            <li className='items-center pb-7'>
              <div className='text-gray-400 text-sm uppercase font-bold block'>Manage</div>
              <ul>
                <li className='items-center'>
                  <Link
                    className='text-gray-800 hover:text-gray-600 text-xs uppercase pt-2 font-bold block'
                    to='/restaurants/all'
                  >
                    My Restaurants
                  </Link>
                </li>
                <li className='items-center'>
                  <a
                    className='text-gray-800 hover:text-gray-600 text-xs uppercase pt-2 font-bold block'
                    href='https://form.jotform.com/222286439067159'
                    rel='noreferrer'
                    target='_blank'
                  >
                    Claim a Restaurant
                  </a>
                </li>
              </ul>
            </li>
            <li className='items-center pb-7'>
              <div className='text-gray-400 text-sm uppercase font-bold block'>Measure</div>
              <ul>
                <li className='items-center'>
                  <div className='text-gray-300 text-xs uppercase pt-2 font-bold block'>
                    Analytics (Coming Soon)
                  </div>
                </li>
                <li className='items-center'>
                  <div className='text-gray-300 text-xs uppercase pt-2 font-bold block'>
                    Performance (Coming Soon)
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <hr className='my-4 md:min-w-full' />
          <ul>
            <div className='text-gray-400 text-sm uppercase font-bold block'>Settings</div>
            <li className='items-center'>
              <Link
                className='text-gray-800 hover:text-gray-600 text-xs uppercase pt-2 font-bold block'
                to='/profile'
              >
                My Profile
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
