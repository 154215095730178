import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';

import AdminNav from './components/Navbars/AdminNav';

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const PaymentStatus = ({ clientSecret }) => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      const paymentAmount = paymentIntent.amount && (paymentIntent.amount / 100).toFixed(2);
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage(`Success! Payment of $${paymentAmount} received.`);
          break;

        case 'processing':
          setMessage("Payment processing. We'll update you when payment is received.");
          break;

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage('Payment failed. Please try another payment method.');
          break;

        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe, clientSecret]);

  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='bg-white shadow-md rounded px-8 py-6 m-4'>
        <div id='payment-message'>{message}</div>
      </div>
    </div>
  );
};

const PostCheckout = () => {
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret',
  );

  return (
    <>
      <AdminNav />
      <Elements options={{ clientSecret }} stripe={stripePromise}>
        <PaymentStatus clientSecret={clientSecret} />
      </Elements>
    </>
  );
};

export default PostCheckout;
