import { useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty } from 'lodash';
import { Tooltip } from '@mui/material';

import AdminNav from './components/Navbars/AdminNav.js';
import Footer from './components/Footers/Footer.js';
import Sidebar from './components/Sidebars/Sidebar.js';
import RestoEditMenu from './components/RestoEditMenu.js';
import { GlobalInfoContext } from './GlobalInfoContextProvider.js';

export default function Restaurants() {
  // "user" object has namespaced (starts with site URL) details added by code that's configured in manage.auth0.com "Actions" section
  const { user } = useAuth0();
  const restoSourceUrl =
    process.env.NODE_ENV === 'development' ? 'restaurantIdsDev' : 'restaurantIds';

  const { [`https://wwww.foodboss.com/${restoSourceUrl}`]: restoIds = [] } = user;
  const { globalRestos, requestRestosForRestoIds } = useContext(GlobalInfoContext);

  const sourcesToDisplay = [2, 3, 4, 5, 13, 17];
  const sourcesToDisplayNames = {
    2: 'delivery.com',
    3: 'EatStreet',
    4: 'Postmates',
    5: 'Foodler',
    13: 'UberEats',
    17: 'DoorDash',
  };

  const sourcesSignupLinks = {
    2: 'https://www.delivery.com',
    3: 'https://www.eatstreet.com',
    4: 'https://postmates.com',
    5: 'https://www.foodler.com',
    13: 'https://www.ubereats.com',
    17: 'https://www.doordash.com',
  };

  useEffect(() => {
    if (isEmpty(globalRestos)) {
      requestRestosForRestoIds(restoIds).catch((err) =>
        console.error(err, 'May want to set up a visible error message for user.'),
      );
    }
  }, [globalRestos, restoIds, requestRestosForRestoIds]);

  return (
    <>
      <Sidebar />
      <div className='relative md:ml-64 bg-gray-100'>
        <AdminNav />
        <div className='pt-8 pb-8 md:pb-32'>
          <div className='px-2 md:px-8'>
            <h2>My Restaurants</h2>
            <div className='grid lg:grid-cols-3 items-start gap-4'>
              <div className='lg:col-span-2'>
                <div className='mb-6 w-full'>
                  {isEmpty(restoIds) ? (
                    <p>You have no restaurants to display</p>
                  ) : (
                    <div>
                      <div className='text-sm p-4 font-bold xl:grid xl:grid-cols-10 resto-table hidden xl:visible'>
                        <div className='col-span-2'>Restaurant</div>
                        <div className='col-span-2'>Location</div>
                        <div className='col-span-3'>
                          <Tooltip title='Basic, Enhanced, Enhanced+, Sponsored' placement='top'>
                            <span>Available Services</span>
                          </Tooltip>
                        </div>
                        <div className='col-span-3'>
                          <Tooltip
                            title='You’ll see unclaimed listings if we find matches'
                            placement='top'
                          >
                            <span>Listing Details</span>
                          </Tooltip>
                        </div>
                      </div>
                      {restoIds.map((restoId, i) => {
                        // By default show dashes, until name and other details retrieved
                        const {
                          gotError = false,

                          name = '---',
                          claim_status,
                          is_hidden,
                          is_active,
                          is_enhanced,
                          phone_number = '---',
                          street = '---',
                          street2,
                          city,
                          state,
                          zip,
                          services = [],
                        } = globalRestos[restoId] || {};

                        if (gotError) {
                          return (
                            <div
                              key={i}
                              className='font-medium text-xs card-sm xl:grid xl:grid-cols-10 mb-2 relative items-center'
                            >
                              <div className='mb-1 text-red-400'>
                                There was an issue loading this restaurant
                              </div>
                            </div>
                          );
                        }

                        // Get list of sources that the resto is not currently working with and could sign up for
                        const sourcesRestoHas = services.map((service) => service.source_id);
                        const sourcesRestoDoesNotHave = sourcesToDisplay.filter(
                          (source) => !sourcesRestoHas.includes(source),
                        );

                        return (
                          <div
                            key={i}
                            className='font-medium text-xs card-sm xl:grid xl:grid-cols-10 mb-2 relative items-center'
                          >
                            <div className='xl:hidden mb-1 text-gray-400'>Restaurant</div>
                            <div className='xl:col-span-2'>
                              <div className='resto-name pr-2 font-bold'>{name}</div>
                              {phone_number && (
                                <div className='text-gray-400 xl:mt-1 whitespace-nowrap'>
                                  <i className='fas fa-phone text-gray-300' /> {phone_number}
                                </div>
                              )}
                            </div>
                            <div className='xl:col-span-2 my-1'>
                              {street} {street2} <br />
                              {city}, {state} {zip}
                            </div>
                            <div className='xl:col-span-3'>
                              <div className='xl:hidden mt-4 mb-1 text-gray-400'>Services</div>
                              {services.map((service, serviceKey) => {
                                const displayServiceName = service.is_order_direct_service
                                  ? 'Restaurant Direct'
                                  : service.name;
                                return (
                                  <div className='inline-block py-1 mr-2' key={serviceKey}>
                                    {service.active ? (
                                      <span className=''>
                                        <i className='fas fa-check-circle text-fb_primary' />{' '}
                                        {displayServiceName}
                                      </span>
                                    ) : (
                                      <span className='text-gray-400'>
                                        <i className='fas fa-plus-circle' /> {displayServiceName}
                                      </span>
                                    )}
                                  </div>
                                );
                              })}

                              {sourcesRestoDoesNotHave.map((service, serviceKey) => {
                                return (
                                  <div className='inline-block py-1 mr-2' key={serviceKey}>
                                    <a
                                      href={sourcesSignupLinks[service]}
                                      rel='noreferrer'
                                      target='_blank'
                                    >
                                      <span className=''>
                                        <i className='fa fa-plus-circle text-gray-400' />{' '}
                                        {sourcesToDisplayNames[service]}
                                      </span>
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                            <div className='xl:col-span-3'>
                              <div className='xl:hidden mt-4 mb-1 text-gray-400'>
                                Listing Details
                              </div>
                              {is_enhanced ? (
                                <div className='font-bold'>Enhanced Listing</div>
                              ) : (
                                <div className='font-bold'>Basic Listing</div>
                              )}
                              {claim_status === 'claimed' ? (
                                <span className='inline-block py-1 mr-2'>
                                  <i className='fas fa-circle text-fb_primary scale-75'></i> Claimed
                                </span>
                              ) : (
                                <span className='inline-block py-1 mr-2'>
                                  <i className='fas fa-circle text-gray-300 scale-75'></i> Unclaimed
                                </span>
                              )}
                              {is_hidden ? (
                                <span className='inline-block py-1 mr-2'>
                                  <i className='fas fa-circle text-gray-300 scale-75'></i> Hidden
                                </span>
                              ) : (
                                <span className='inline-block py-1 mr-2'>
                                  <i className='fas fa-circle text-fb_primary scale-75'></i> Visible
                                </span>
                              )}
                              {is_active ? (
                                <span className='inline-block py-1 mr-2'>
                                  <i className='fas fa-circle text-fb_primary scale-75'></i> Enabled
                                </span>
                              ) : (
                                <span className='inline-block py-1 mr-2'>
                                  <i className='fas fa-circle text-gray-300 scale-75'></i> Disabled
                                </span>
                              )}
                            </div>
                            <div className='absolute right-3 top-3'>
                              <RestoEditMenu
                                restoId={restoId}
                                claimStatus={claim_status}
                                isEnhanced={is_enhanced}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className='text-center'>
                  <h5>Need to manage a new restaurant listing?</h5>
                  <a
                    className='button mb-8 shadow-sm cursor-pointer'
                    href='https://form.jotform.com/222286439067159'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fa fa-plus'></i> Claim A Restaurant
                  </a>
                </div>
              </div>
              <div>
                <div className='card mb-6'>
                  <h3 className='mb-0'>Be in the Know...</h3>
                  <p className='mb-4 text-xs'>
                    from{' '}
                    <a href='https://experience.foodboss.com/' rel='noreferrer' target='_blank'>
                      Experience
                    </a>{' '}
                    FoodBoss
                  </p>
                  <h5 className='mb-2'>
                    Ways To Use Social Media Marketing to Promote Your Local Restaurant
                  </h5>
                  <p className='mb-2 text-xs'>
                    With the right strategy, social media marketing is free and can help get your
                    business noticed by potential customers. Here are 7 ideas you can use to promote
                    your local restaurant with social media marketing.
                  </p>
                  <a
                    href='https://experience.foodboss.com/partners-7-ways-to-use-social-media-marketing-to-promote-your-local-restaurant/'
                    rel='noreferrer'
                    target='_blank'
                    className='text-xs link'
                  >
                    {' '}
                    Read More
                  </a>
                  <hr />
                  <h5 className='mb-2'>
                    5 Tips to Recession-Proof Your Restaurant Marketing Strategy
                  </h5>
                  <p className='mb-2 text-xs'>
                    You might think a recession is the worst time possible to open a new restaurant,
                    but this couldn’t be further from the truth. While larger chains struggle in the
                    face of reduced spending, smaller independents that focus on affordability and
                    value have thrived in recent years.
                  </p>
                  <a
                    href='https://experience.foodboss.com/partners-7-ways-to-use-social-media-marketing-to-promote-your-local-restaurant/'
                    rel='noreferrer'
                    target='_blank'
                    className='text-xs link'
                  >
                    {' '}
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
