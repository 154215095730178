export default function Footer() {
  return (
    <>
      <footer>
        <nav className='py-4 bg-gray-100'>
          <div className='px-8 text-gray-500 text-xs inline-block'>
            Copyright &copy; {new Date().getFullYear()} by FoodBoss
          </div>
        </nav>
      </footer>
    </>
  );
}
