export default function AnalyticsRestoSelect({ restos, setActiveRestoId }) {
  // Filter out restos that errored out on fetching, for the rest pull out their name and id
  const selectItems = Object.values(restos)
    .filter((entry) => !entry.gotError)
    .map((resto) => ({ label: resto.name, value: resto.id }));

  return (
    <select
      className='my-4 p-4 md:w-1/3 sm:w-full'
      onChange={(e) => setActiveRestoId(e.currentTarget.value)}
    >
      {selectItems.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
}
