import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import AdminNav from './components/Navbars/AdminNav';
import Footer from './components/Footers/Footer.js';
import Sidebar from './components/Sidebars/Sidebar.js';

export default function Subscribe() {
  const { restoId } = useParams();
  const [readyToSubscribe, setReadyToSubscribe] = useState(false);
  const [restoName, setRestoName] = useState('');
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const fetchData = async () => {
      // Get the values of the resto fields that are in the main resto table
      const coreRestoResponse = await fetch(`/.netlify/functions/get-resto-by-id?id=${restoId}`);
      if (!coreRestoResponse.ok) {
        setErrorMessage('Could not get current restaurant details.');
        return;
      }
      const coreResto = await coreRestoResponse.json();
      const restoName = get(coreResto, 'name');
      setReadyToSubscribe(true);
      setRestoName(restoName);
    };

    fetchData().catch(() => setErrorMessage('Could not get current restaurant details.'));
  }, [restoId]);

  if (errorMessage) {
    return (
      <>
        <AdminNav />
        <div className='mt-10 text-center text-lg text-red-500'>
          <div>Something went wrong, please try reloading the page.</div>
          <div>Details: {errorMessage}</div>
        </div>
      </>
    );
  }

  if (!readyToSubscribe) {
    return (
      <>
        <AdminNav />
        <div className='mt-10 text-center w-100 text-xl'>Loading...</div>
      </>
    );
  }
  return (
    <>
      <Sidebar />
      <div className='relative md:ml-64 bg-gray-100 flex flex-col h-screen'>
        <AdminNav />
        <div className='py-8 grow flex flex-col items-center justify-center'>
          <div className='px-8'>
            <div className='mb-8'>
              <div className='text-center text-2xl'>{restoName}</div>
              <h2 className='text-center'>Upgrade Your Listing</h2>
              <p className='text-center text-gray-500'>Try it free for 30 days!</p>
            </div>
            <div className='grid xl:grid-cols-3 gap-4'>
              <div className='card'>
                <div className='text-center mb-4'>
                  <h3 className='mb-0'>Enhanced</h3>
                  <div className='text-sm text-gray-500'>Get started with boosted features</div>
                </div>

                <div className='my-6 flex items-center justify-center'>
                  <div className='text-6xl text-gray-300'>$</div>
                  <span className='text-6xl font-extrabold'>
                    9<sup className='text-xl -top-6'>.99</sup>
                  </span>
                  <div className='text-xs text-gray-500 ml-2'>
                    {' '}
                    per listing <br /> paid monthly
                  </div>
                </div>
                <hr />
                <ul className='mb-4'>
                  <li>
                    <i className='fas fa-check text-fb_primary mr-2' /> 30 Day Free Trial
                  </li>
                  <li>
                    <i className='fas fa-check text-fb_primary mr-2' /> Featured Menu Tab
                  </li>
                  <li>
                    <i className='fas fa-check text-fb_primary mr-2' /> Suppress Competitor Ads
                  </li>
                </ul>
                <a
                  className='button button-full'
                  target='_blank'
                  rel='noreferrer'
                  href={`https://buy.stripe.com/7sI8zF5J364O0ZqeUV?client_reference_id=${restoId}`}
                >
                  Try for Free
                </a>
              </div>
              <div className='card relative'>
                <div className='-top-3 -ml-8 w-full text-center absolute'>
                  <span className='bg-fb_blue px-4 py-2 rounded text-white font-bold text-xs shadow'>
                    Best Deal
                  </span>
                </div>
                <div className='text-center mb-4'>
                  <h3 className='mb-0'>Enhanced (Annual)</h3>
                  <div className='text-sm text-gray-500'>
                    <strong className='text-fb_blue'>Save 20%</strong> with annual subscription
                  </div>
                </div>
                <div className='my-6 flex items-center justify-center'>
                  <div className='text-6xl text-gray-300'>$</div>
                  <div className='jumbo-text'>96</div>
                  <div className='text-xs text-gray-500 ml-2'>
                    {' '}
                    per listing <br /> paid annually
                  </div>
                </div>
                <hr />
                <ul className='mb-4'>
                  <li>
                    <i className='fas fa-check text-fb_primary mr-2' /> 30 Day Free Trial
                  </li>
                  <li>
                    <i className='fas fa-check text-fb_primary mr-2' /> Featured Menu Tab
                  </li>
                  <li>
                    <i className='fas fa-check text-fb_primary mr-2' /> Suppress Competitor Ads
                  </li>
                </ul>
                <a
                  className='button button-full'
                  target='_blank'
                  rel='noreferrer'
                  href={`https://buy.stripe.com/8wM8zFc7r78S37y288?client_reference_id=${restoId}`}
                >
                  Try for Free
                </a>
              </div>
              <div className='self-center p-5 lg:p-8'>
                <div className='text-center mb-4'>
                  <h3 className='mb-0'>
                    Enhanced
                    <i className='fas fa-plus scale-75' />
                  </h3>
                  <div className='text-sm text-gray-500'>Take your listing to the next level</div>
                </div>
                <ul className='my-6'>
                  <li>
                    <i className='fas fa-check text-fb_primary mr-2' /> Special brand & promo
                    abilities
                  </li>
                  <li>
                    <i className='fas fa-check text-fb_primary mr-2' /> Enterprise reporting
                  </li>
                  <li>
                    <i className='fas fa-check text-fb_primary mr-2' /> Partner
                    Suppression/Management
                  </li>
                </ul>
                <a className='button button-full' href='mailto:support@foodboss.com'>
                  <i className='fas fa-envelope' /> Contact us for pricing
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
