import { useAuth0 } from '@auth0/auth0-react';

export default function CardProfile() {
  const { user } = useAuth0();
  const updatedDate = new Date(user.updated_at).toString();

  return (
    <div className='card flex flex-col items-center text-center pt-8'>
      <img className='rounded-full -mt-16' src={user.picture} width={120} />
      <div className='text-lg text-gray-700 font-bold pt-4 break-all'>{user.name}</div>
      <div className='text-gray-500 break-all'>{user.email}</div>

      <div className='text-xs text-gray-400 pt-6'>Updated: {updatedDate}</div>
    </div>
  );
}
