import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import LogoutButton from '../../components/LogoutButton';
import logo from '../../assets/logo-foodboss-partners.svg';

export default function AdminNav() {
  const { user } = useAuth0();

  return (
    <nav className='py-4 bg-fb_secondary'>
      <div className='px-8 flex justify-between items-center'>
        <img src={logo} alt='FoodBoss Partners' style={{ height: '20px' }} />
        <Link className='text-sm text-white' to='/profile'>
          Hello, <strong className='underline'>{user.nickname}</strong>
        </Link>
        <LogoutButton />
      </div>
    </nav>
  );
}
