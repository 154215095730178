import MainNav from './components/Navbars/MainNav.js';
import Footer from './components/Footers/Footer.js';
import heather from './assets/heather-stege-360x360.jpg';

export default function PartnersHome() {
  return (
    <>
      <MainNav />
      <main>
        <div className='bg-gradient-to-t from-white to-gray-200 home-hero'>
          <div className='grid-container'>
            <div className='card md:w-3/4 lg:w-1/2'>
              <h1 className='jumbo-text'>Build trust with FoodBoss</h1>
              <p className='lead-text'>
                Get more customers to choose you by building a strong presence on Foodboss. Manage
                your listings for free or upgrade them to stand out.
              </p>
              <a
                className='button my-6 mr-4'
                href='https://form.jotform.com/222286439067159'
                rel='noreferrer'
                target='_blank'
              >
                Claim A Restaurant
              </a>
              <a className='link text-fb_primary' href='/restaurants/all'>
                <strong>Manage Your Listings &rarr;</strong>
              </a>
            </div>
          </div>
        </div>
        <div className='bg-gray-100'>
          <div className='py-24'>
            <div className='grid-container'>
              <div className='flex gap-16 justify-center items-center'>
                <div className='md:w-1/2'>
                  <h2>People come to FoodBoss for ONE thing, to search for FOOD.</h2>
                  <p className='lead-text'>
                    No reviews or gimmicks, real users searching for how they can order your food
                    online.
                  </p>
                </div>

                <div className='md:w-1/2'>
                  <div className='flex card gap-4'>
                    <img src={heather} alt='Heather Stege' style={{ height: '150px' }} />
                    <p className='text-xs'>
                      “The challenge for restaurants is being able to serve customers through the
                      users preferred channels, while still providing them with exceptional food.{' '}
                      <strong>FoodBoss helps simplify</strong> that by offering multiple options,
                      including our own, to attract customers.”
                      <br />
                      <br />
                      -- <em>Lou Malnati’s president,</em> <strong>Heather Stege</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-white py-12'>
          <div className='text-center'>
            <h2>Get started for free</h2>
            <a
              className='button button-large mb-6'
              href='https://form.jotform.com/222286439067159'
              rel='noreferrer'
              target='_blank'
            >
              Claim Your Restaurant on FoodBoss &rarr;
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
