import { useState } from 'react';
import { Link } from 'react-router-dom';

import { MenuItem, Menu } from '@mui/material';

export default function RestoEditMenu({ restoId, claimStatus, isEnhanced }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='cursor-pointer'
      >
        <div className='whitespace-nowrap'>
          <i className='fas fa-circle scale-25 text-gray-500'></i>
          <i className='fas fa-circle scale-25 text-gray-500 -ml-1'></i>
          <i className='fas fa-circle scale-25 text-gray-500 -ml-1'></i>
        </div>
      </div>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link to={`/restaurants/${restoId}/edit`} className='text-xs'>
            Edit Listing
          </Link>
        </MenuItem>
        {claimStatus !== 'claimed' && (
          <MenuItem onClick={handleClose}>
            <a
              href='https://form.jotform.com/222286439067159'
              className='text-xs'
              target='_blank'
              rel='noreferrer'
            >
              Claim Listing
            </a>
          </MenuItem>
        )}
        {!isEnhanced && (
          <MenuItem onClick={handleClose}>
            <Link to={`/restaurants/${restoId}/subscribe`} className='text-xs'>
              Upgrade Listing
            </Link>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
// TODO: it's possible to click within the MenuItem but not within the Link, which is confusing, seems like the link is not working when it happens
