import { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { get, isEmpty } from 'lodash';

import { GlobalInfoContext } from './GlobalInfoContextProvider.js';
import AdminNav from './components/Navbars/AdminNav.js';
import Footer from './components/Footers/Footer.js';
import Sidebar from './components/Sidebars/Sidebar.js';
import CardStats from './components/Cards/CardStats.js';
import AnalyticsRestoSelect from './components/Cards/AnalyticsRestoSelect.js';

export default function Analytics() {
  const ANALYTICS_PERIOD_LENGTH_IN_DAYS = 7;

  const { user } = useAuth0();
  const restoSourceUrl =
    process.env.NODE_ENV === 'development' ? 'restaurantIdsDev' : 'restaurantIds';

  const { [`https://wwww.foodboss.com/${restoSourceUrl}`]: restoIds = [] } = user;
  const { globalRestos, requestRestosForRestoIds } = useContext(GlobalInfoContext);

  useEffect(() => {
    if (!isEmpty(globalRestos)) return;

    requestRestosForRestoIds(restoIds).catch((err) =>
      console.error(err, 'May want to set up a visible error message for user.'),
    );
  }, [globalRestos, requestRestosForRestoIds, restoIds]);

  const [activeRestoId, setActiveRestoId] = useState(get(restoIds, 0));
  const [dataReady, setDataReady] = useState(false);

  // Create an initial to-populate analyticsDataByRestoId object of the form { id1: null, id2: null ... }
  const initialEmptyRestoIdAnalyticsMap = Object.fromEntries(restoIds.map((id) => [id, null]));
  const [analyticsDataByRestoId, setAnalyticsDataByRestoId] = useState(
    initialEmptyRestoIdAnalyticsMap,
  );

  // Whenever the active resto is changed, if we haven't already, request the analytics data for all days in current
  //    period and previous period, as defined by ANALYTICS_PERIOD_LENGTH_IN_DAYS
  useEffect(() => {
    setDataReady(false);
    if (!isEmpty(analyticsDataByRestoId[activeRestoId])) {
      setDataReady(true);
      return;
    }

    const fetchData = async () => {
      const summedStatsForRestoInCurrentPeriod = {};
      const summedStatsForRestoInPreviousPeriod = {};

      // Async function that will be done for each day in the current and previous period
      const fetchAnalyticsData = async (daysAgo) => {
        // Going to request the analytics data by directly hitting the appropriate (staging vs prod) API route, to get result faster than using intermediary Netlify function
        const apiOrigin = process.env.REACT_APP_FOODBOSS_API_ORIGIN;
        const analyticsResponse = await fetch(
          `${apiOrigin}/api/v2/partners-analytics/${activeRestoId}?days_ago=${daysAgo}`,
          {
            headers: { Accept: 'application/json' },
          },
        );

        if (!analyticsResponse.ok) {
          setAnalyticsDataByRestoId({});
          throw Error(
            'Failed to get the day of analytics data for',
            activeRestoId,
            daysAgo,
            'days ago',
          );
        }

        // Add details of analytics response to either summed stats for current period or previous period
        const analytics = await analyticsResponse.json();
        for (let statName in analytics) {
          const stat = analytics[statName].count;
          ((summedStatsObject) => {
            summedStatsObject[statName] = get(summedStatsObject, statName, 0) + stat;
          })(
            daysAgo < ANALYTICS_PERIOD_LENGTH_IN_DAYS
              ? summedStatsForRestoInCurrentPeriod
              : summedStatsForRestoInPreviousPeriod,
          );
        }
      };

      // Create the array of promises to be done in parallel, then execute and update state to show results
      const fetchPromises = [];
      for (let daysAgo = 0; daysAgo < ANALYTICS_PERIOD_LENGTH_IN_DAYS * 2; daysAgo++) {
        fetchPromises.push(fetchAnalyticsData(daysAgo));
      }
      try {
        await Promise.all(fetchPromises);

        const updatedData = {
          ...analyticsDataByRestoId,
          [activeRestoId]: {
            currentPeriod: summedStatsForRestoInCurrentPeriod,
            previousPeriod: summedStatsForRestoInPreviousPeriod,
          },
        };
        setAnalyticsDataByRestoId(updatedData);
        setDataReady(true);
      } catch (err) {
        console.error(err, 'May want to set up a visible error message for user.');
      }
    };

    fetchData();
  }, [activeRestoId, analyticsDataByRestoId]);

  const analyticsData = analyticsDataByRestoId[activeRestoId];

  const statsPeriodDescription = `Current ${ANALYTICS_PERIOD_LENGTH_IN_DAYS}-day period over previous`;

  // Some accounts might not have any claimed restos, in which case we should display a nice blank page
  if (isEmpty(restoIds)) {
    return (
      <>
        <Sidebar />
        <div className='relative md:ml-64 bg-gray-200'>
          <AdminNav />

          <div className='relative bg-gray-100 pt-8 pb-8 md:pb-32'>
            <h2 className='mb-6 px-3 md:px-8'>Analytics</h2>
            <div className='px-3 md:px-8'>
              <div className='bg-white p-6 rounded shadow-md'>
                <p className='text-lg mb-2'>
                  You do not have any restaurants to display analytics data for yet.
                </p>
                <a href='https://www.foodboss.com/claim-restaurant' className='link'>
                  Claim a restaurant
                </a>{' '}
                to get started.
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </>
    );
  }

  return (
    <>
      <Sidebar />
      <div className='relative md:ml-64 bg-gray-200'>
        <AdminNav />

        <div className='relative bg-gray-100 pt-8 pb-8 md:pb-32'>
          <h2 className='px-3 md:px-8'>Analytics</h2>
          <div className='px-3 md:px-8'>
            <AnalyticsRestoSelect restos={globalRestos} setActiveRestoId={setActiveRestoId} />
          </div>
          <div className='md:px-4 mx-auto w-full'>
            {/* Card stats */}
            <div className='flex flex-wrap'>
              <div className='w-full lg:w-6/12 xl:w-3/12 px-4 my-4'>
                <CardStats
                  dataReady={dataReady}
                  data={analyticsData}
                  statKey='pageViewStats'
                  statName='Page Views'
                  statDescription={statsPeriodDescription}
                  statIconName='fas fa-chart-bar'
                  statIconColor='bg-fb_secondary'
                />
              </div>

              <div className='w-full lg:w-6/12 xl:w-3/12 px-4 my-4'>
                <CardStats
                  dataReady={dataReady}
                  data={analyticsData}
                  statKey='callStats'
                  statName='Phone Calls'
                  statDescription={statsPeriodDescription}
                  statIconName='fas fa-chart-line'
                  statIconColor='bg-fb_secondary'
                />
              </div>

              <div className='w-full lg:w-6/12 xl:w-3/12 px-4 my-4'>
                <CardStats
                  dataReady={dataReady}
                  data={analyticsData}
                  statKey='selectServiceStats'
                  statName='Pass Throughs'
                  statDescription={statsPeriodDescription}
                  statIconName='fas fa-dollar-sign'
                  statIconColor='bg-fb_secondary'
                />
              </div>

              <div className='w-full lg:w-6/12 xl:w-3/12 px-4 my-4'>
                <CardStats
                  dataReady={dataReady}
                  data={analyticsData}
                  statKey='searchStats'
                  statName='Direct Searches'
                  statDescription={statsPeriodDescription}
                  statIconName='fas fa-search'
                  statIconColor='bg-fb_secondary'
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
