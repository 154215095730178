import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import LogoutButton from '../../components/LogoutButton';
import LoginButton from '../../components/LoginButton';
import logo from '../../assets/logo-foodboss-partners.svg';

export default function MainNav() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  return (
    <nav className='py-4 bg-fb_secondary'>
      <div className='px-8 flex justify-between items-center'>
        <img src={logo} alt='FoodBoss Partners' style={{ height: '20px' }} />
        {isLoading ? (
          <button className='button button-small'>Loading ...</button>
        ) : (
          <>
            {isAuthenticated && (
              <>
                <Link className='text-sm text-white' to='/profile'>
                  Hello, <strong>{user.nickname}</strong>
                </Link>
                <LogoutButton />
              </>
            )}
            {!isAuthenticated && <LoginButton />}
          </>
        )}
      </div>
    </nav>
  );
}
