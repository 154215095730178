import PartnersHome from './PartnersHome';
import Restaurants from './Restaurants';
import EditRestaurant from './EditRestaurant';
import Analytics from './Analytics';
import Profile from './Profile';
import StripeCheckout from './StripeCheckout';
import PostCheckout from './PostCheckout';
import Subscribe from './Subscribe';
import LoginButton from './components/LoginButton';
import MainNav from './components/Navbars/MainNav';

import '@fortawesome/fontawesome-free/css/all.min.css';

import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';

function App() {
  // TODO could have MainNav here instead of in each component
  return (
    <Router>
      <Routes>
        <Route path='/*' element={<PartnersHome />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/analytics' element={<ProtectedRoute component={Analytics} />} />
        <Route path='/profile' element={<ProtectedRoute component={Profile} />} />
        <Route path='/restaurants/all' element={<ProtectedRoute component={Restaurants} />} />
        <Route
          path='/restaurants/:restoId/edit'
          element={<ProtectedRoute component={EditRestaurant} />}
        />
        <Route
          path='/restaurants/:restoId/subscribe'
          element={<ProtectedRoute component={Subscribe} />}
        />
        <Route path='/purchase-credits' element={<ProtectedRoute component={StripeCheckout} />} />
        <Route path='/post-checkout' element={<ProtectedRoute component={PostCheckout} />} />
      </Routes>
    </Router>
  );
}

// TODO: it didn't take me to the route i was attempting to go to after login. It said /analytics but it showed profile
const ProtectedRoute = ({ component: Component }) => {
  const ComponentWithAuth = withAuthenticationRequired(Component, {
    onRedirecting: () => <LoadingPage />,
  });

  return <ComponentWithAuth />;
};

const LoginPage = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  if (isAuthenticated) return <Navigate to='/profile' replace />;

  let { from } = location.state || { from: { pathname: '/' } };
  return (
    <div>
      <p>You must log in to view the page at {from.pathname}</p>
      <LoginButton />
    </div>
  );
};

const LoadingPage = () => {
  return (
    <>
      <MainNav />
      <div className='mt-10 text-center w-100 text-xl'>Loading...</div>
    </>
  );
};

export default App;
