import { Field } from 'formik';

import timeList from '../../constants/time.json';

export default function EditOpenCloseHours({ dayOfWeek, dayOfWeekShort }) {
  return (
    <div className='flex'>
      <div className='form-field'>
        <label className='mb-1 text-xs text-gray-400'>{dayOfWeek} Open</label>
        <Field
          id={`resto_hours_${dayOfWeekShort}_start`}
          name={`resto_hours_${dayOfWeekShort}_start`}
          as='select'
          className='w-auto mr-1'
        >
          {timeList.map((time, index) => (
            <option key={index} value={time.value}>
              {time.name}
            </option>
          ))}
        </Field>
      </div>
      <div className='form-field'>
        <label className='mb-1 text-xs text-gray-400'>{dayOfWeek} Close</label>
        <Field
          id={`resto_hours_${dayOfWeekShort}_end`}
          name={`resto_hours_${dayOfWeekShort}_end`}
          as='select'
          className='w-auto mr-1'
        >
          {timeList.map((time, index) => (
            <option key={index} value={time.value}>
              {time.name}
            </option>
          ))}
        </Field>
      </div>
    </div>
  );
}
