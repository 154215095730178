import { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

export default function CheckoutForm({ showPaymentElement }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Return URL will depend on if this is running locally, in deploy preview, on prod
    const origin = window.location.origin;
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${origin}/post-checkout`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <>
      {isLoading && <div className='mt-10 text-center w-100 text-xl'>Loading...</div>}
      {showPaymentElement && (
        <form id='payment-form' onSubmit={handleSubmit} className='mt-4 flex flex-col items-center'>
          <PaymentElement
            id='payment-element'
            className='w-1/3'
            options={paymentElementOptions}
            onReady={() => setIsLoading(false)}
          />
          {isLoading || !stripe || !elements || (
            <button id='submit' className='button mt-6 py-3 px-4 rounded-md' type='submit'>
              Pay Now
            </button>
          )}
        </form>
      )}
      {message && <div id='payment-message'>{message}</div>}
    </>
  );
}
