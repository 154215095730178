import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';

import './styles/global.css';
import App from './App';
import GlobalInfoContextProvider from './GlobalInfoContextProvider';

// Use React's Context to create a global state for use across components.... test in restaurants component.
//    it should see if the restaurants in Context are specified, request them and update context if not

// The Auth0Provider wraps around the whole App component so that auth-related information can be accessed
//    from any point in the application, to see logged-in user's info, or make log out possible, for example
const AppComponentWithAuth0Provider = () => (
  <Auth0Provider
    domain='dev-l7kee0s7.us.auth0.com'
    clientId='ZNHfmxTdQVWnpfzghmUrWiRUh1ujU3T7'
    // Additional details added by the Auth0 Login Action ("advertiserKevelId") are included as part of this scope
    audience={process.env.REACT_APP_JWT_AUDIENCE}
    scope='openid profile'
    // After login, redirect them to the profile page
    redirectUri={`${window.location.origin}/profile`}
  >
    <GlobalInfoContextProvider>
      <App />
    </GlobalInfoContextProvider>
  </Auth0Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AppComponentWithAuth0Provider />
  </React.StrictMode>,
);
