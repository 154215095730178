import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { isEmpty } from 'lodash';

import AdminNav from './components/Navbars/AdminNav';
import Footer from './components/Footers/Footer.js';
import CheckoutForm from './components/Forms/CheckoutForm';

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function StripeCheckout() {
  // Pull out information identifying the advertiser logged in right now, to be used in payment callback
  const { user } = useAuth0();
  const { sub: auth0Id, 'https://wwww.foodboss.com/kevelAdvertiserId': kevelId } = user;

  const [amountOfCredits, setAmountOfCredits] = useState(0);
  const [clientSecret, setClientSecret] = useState('');
  const [showCreditsSelector, setShowCreditsSelector] = useState(true);
  const [showPaymentElement, setShowPaymentElement] = useState(false);

  // On first load, check if there are URL params from a payment attempt
  useEffect(() => {
    const hasURLParamsFromPaymentAttempt = !isEmpty(window.location.search);
    if (hasURLParamsFromPaymentAttempt) {
      const urlClientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret',
      );
      setClientSecret(urlClientSecret);
      // Don't show the credits selector if they're coming from a payment attempt
      setShowCreditsSelector(false);
    }
  }, []);

  // Whenever the amount of credits changes, create a new PaymentIntent[]
  useEffect(() => {
    if (amountOfCredits === 0) return;

    // Create PaymentIntent whenever they change the amount of credits they want to buy
    fetch('/.netlify/functions/stripe-create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amountOfCredits, auth0Id, kevelId }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setShowPaymentElement(true);
      });
  }, [amountOfCredits, auth0Id, kevelId]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAmountOfCredits(e.target.amount.value);
    setShowCreditsSelector(false);
  };

  return (
    <div className='flex flex-col h-screen'>
      <AdminNav />
      <div className='grow'>
        {showCreditsSelector && (
          <form className='max-w-sm mx-auto pt-16' onSubmit={handleSubmit}>
            <h1 className='text-2xl uppercase'>Buy Credits</h1>
            <div className='flex flex-col items-center mt-4'>
              <label className='uppercase' htmlFor='amount'>
                Amount
              </label>
              <select className='mt-2 p-2 bg-white rounded-md' id='amount' name='amount'>
                <option value='2500'>$25</option>
                <option value='5000'>$50</option>
                <option value='7500'>$75</option>
                <option value='10000'>$100</option>
                <option value='12500'>$125</option>
                <option value='15000'>$150</option>
                <option value='17500'>$175</option>
                <option value='20000'>$200</option>
                <option value='22500'>$225</option>
                <option value='25000'>$250</option>
                <option value='27500'>$275</option>
                <option value='30000'>$300</option>
                <option value='32500'>$325</option>
                <option value='35000'>$350</option>
                <option value='37500'>$375</option>
                <option value='40000'>$400</option>
                <option value='42500'>$425</option>
                <option value='45000'>$450</option>
                <option value='47500'>$475</option>
                <option value='50000'>$500</option>
              </select>
              <button id='submit' className='button mt-6 w-full py-3 px-4 rounded-md' type='submit'>
                Submit
              </button>
            </div>
          </form>
        )}
        {clientSecret && ( // when clientSecret isn't set, Elements will throw an error
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm showPaymentElement={showPaymentElement} />
          </Elements>
        )}
      </div>
      <Footer />
    </div>
  );
}
