import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from '@mui/material';
import { isEmpty, isNil, omitBy, pick, pickBy } from 'lodash';

import statesList from '../../constants/states.json';
import EditOpenCloseHours from './EditOpenCloseHours';

// Keys of resto that are supported for modification by this form
const relevantKeys = ['name', 'phone_number', 'street', 'street2', 'city', 'state', 'zip'];

const daysOfWeek = [
  { full: 'Monday', short: 'mon', upperCase: 'Mon' },
  { full: 'Tuesday', short: 'tue', upperCase: 'Tue' },
  { full: 'Wednesday', short: 'wed', upperCase: 'Wed' },
  { full: 'Thursday', short: 'thu', upperCase: 'Thu' },
  { full: 'Friday', short: 'fri', upperCase: 'Fri' },
  { full: 'Saturday', short: 'sat', upperCase: 'Sat' },
  { full: 'Sunday', short: 'sun', upperCase: 'Sun' },
];

export default function EditRestaurantForm({ restoId }) {
  const apiOrigin = process.env.REACT_APP_FOODBOSS_API_ORIGIN;

  const [initialRestoDetails, setInitialRestoDetails] = useState({});
  const [readyToShowForm, setReadyToShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Keep track of how many hour blocks exist per day of the week
  const [numHours, setNumHours] = useState({
    mon: 0,
    tue: 0,
    wed: 0,
    thu: 0,
    fri: 0,
    sat: 0,
    sun: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      // Get the values of the resto fields that are in the main resto table
      const coreRestoResponse = await fetch(`/.netlify/functions/get-resto-by-id?id=${restoId}`);
      if (!coreRestoResponse.ok) {
        setErrorMessage('Could not get current restaurant details.');
        return;
      }
      const coreResto = await coreRestoResponse.json();

      // Get the current over-ride values for the resto that would have been submitted previously
      const revisedRestoResponse = await fetch(`${apiOrigin}/api/v2/claimed-resto/${restoId}`);
      if (!revisedRestoResponse.ok) {
        setErrorMessage('Could not get updated restaurant details.');
        return;
      }
      const revisedResto = await revisedRestoResponse.json();

      // The object whose values will populate the form is made from these rules:
      //    - _start with all resto entry's { key: values } for the relevant keys
      //    - overwrite any with the value that claimed-resto has, unless it's null, which means no override has been provided
      const coreRestoWithOnlyRelevantFields = pick(coreResto, relevantKeys);
      const revisedRestoFieldsWithValues = omitBy(revisedResto, isNil);

      const currentClaimedResto = {
        resto_hours: {}, // empty by default, will be overwritten if present in revisedRestoFieldsWithValues
        ...coreRestoWithOnlyRelevantFields,
        ...revisedRestoFieldsWithValues,
      };

      setInitialRestoDetails(currentClaimedResto);

      // Set the number of hour blocks per day of the week, based on the number of entries for each resto_hours subkey,
      //    divided by 2 (block is a pair of start/end times)
      let newNumHours = {};
      for (let day of daysOfWeek) {
        newNumHours[day.short] =
          Object.keys(currentClaimedResto.resto_hours[`restoHourFields${day.upperCase}`]).length /
          2;
      }
      setNumHours(newNumHours);

      setReadyToShowForm(true);
    };

    fetchData().catch(() =>
      setErrorMessage('Failed to grab and combine old and current restaurant details.'),
    );
  }, [apiOrigin, restoId]);

  if (errorMessage) {
    return (
      <>
        <div className='text-lg text-red-500 px-6 py-3'>
          Something went wrong, please try reloading the page.
        </div>
        <div className='text-lg text-red-500 px-6 py-3'>Details: {errorMessage}</div>
      </>
    );
  }

  if (!readyToShowForm) {
    return <div className='text-xl p-10'>Loading current restaurant details...</div>;
  }
  return (
    <div className='pt-8 pb-8 px-2 md:pb-32 md:px-8'>
      <h2>Edit Restaurant Details</h2>

      <div className='card lg:w-2/3 w-full'>
        <Formik
          initialValues={{
            name: initialRestoDetails.name,
            phone_number: initialRestoDetails.phone_number,
            street: initialRestoDetails.street,
            street2: initialRestoDetails.street2,
            city: initialRestoDetails.city,
            state: initialRestoDetails.state,
            zip: initialRestoDetails.zip,
            // Each of these, if data is present, has the form of { resto_hours_mon_0_start: '10:00', resto_hours_mon_0_end: '20:00' },
            //    and the EditOpenCloseHours component will receive that data via the 'name' prop
            ...daysOfWeek.reduce((prev, curr) => {
              return {
                ...prev,
                ...initialRestoDetails.resto_hours[`restoHourFields${curr.upperCase}`],
              };
            }, {}),
          }}
          validate={(values) => {
            const errors = {};
            // Validate phone number
            if (!values.phone_number || !/^[0-9]{10}$/.test(values.phone_number)) {
              errors.phone_number = 'Please enter a valid 10-digit phone number';
            }

            // Ensure they've entered a valid zip code
            if (!values.zip || !/^[0-9]{5}$/.test(values.zip)) {
              errors.zip = 'You must enter a valid 5-digit zip code.';
            }

            return errors;
          }}
          onSubmit={async (finalForm, actions) => {
            // Filter function to pull all resto hours out of the form fields
            const keyIsDayOfRestoHours = (_, key) => key.includes('resto_hours_');

            // Creates a function that checks if the given key belongs to a certain day's resto hours
            const makeKeyIsSpecificDayRestoHours = (dayShort) => (_, key) =>
              key.includes(`resto_hours_${dayShort}`);

            // Build the submitted resto_hours object programmatically, using global daysOfWeek object
            const resto_hours = daysOfWeek.reduce((acc, day) => {
              const filterFunction = makeKeyIsSpecificDayRestoHours(day.short);
              return {
                ...acc,
                [`restoHourFields${day.upperCase}`]: pickBy(finalForm, filterFunction),
              };
            }, {});

            const finalRestoDetails = {
              ...omitBy(finalForm, keyIsDayOfRestoHours),
              resto_hours,
            };

            const editClaimedRestoResponse = await fetch('/.netlify/functions/edit-claimed-resto', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ finalRestoDetails, initialRestoDetails, restoId }),
            }).catch(() => setErrorMessage('Failed to submit updated values.'));

            if (!editClaimedRestoResponse.ok) {
              setErrorMessage('Failed to submit updated values.');
            }

            actions.resetForm({ values: finalForm });
            setShowSuccessMessage(true);
          }}
        >
          {({ dirty, errors, isSubmitting }) => {
            const formIsSubmittable = dirty && isEmpty(errors) && !isSubmitting;

            return (
              <Form>
                {/* Show message aftert they've changed any values to remind them to "Submit".
                    whitespace-pre allows blank space to hold space when no message present */}
                <div className='values-edited-alert text-red-400 text-lg mb-3 whitespace-pre'>
                  {dirty && !isSubmitting ? 'Changes not yet saved.' : ' '}
                </div>

                <div className='form-field'>
                  <label htmlFor='name'>Restaurant Name</label>
                  <Field
                    type='text'
                    id='name'
                    name='name'
                    variant='outlined'
                    as={TextField}
                    className='w-full'
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='phone_number'>Phone Number</label>
                  <Field
                    type='text'
                    id='phone_number'
                    name='phone_number'
                    variant='outlined'
                    as={TextField}
                    className='w-full'
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='street'>Street Address</label>
                  <Field
                    type='text'
                    id='street'
                    name='street'
                    variant='outlined'
                    as={TextField}
                    className='w-full'
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='street2'>Unit/Suite Number</label>
                  <Field
                    type='text'
                    id='street2'
                    name='street2'
                    variant='outlined'
                    as={TextField}
                    className='w-full'
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='city'>City</label>
                  <Field
                    type='text'
                    id='city'
                    name='city'
                    variant='outlined'
                    as={TextField}
                    className='w-full'
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='state'>State</label>
                  <Field id='state' name='state' as='select' className='w-full'>
                    {statesList.map((state, index) => (
                      <option key={index} value={state.value}>
                        {state.name}
                      </option>
                    ))}
                  </Field>
                </div>

                <div className='form-field'>
                  <label htmlFor='zip'>Zip Code</label>
                  <Field
                    type='text'
                    id='zip'
                    name='zip'
                    variant='outlined'
                    as={TextField}
                    className='w-full'
                  />
                </div>

                <div className='form-field'>
                  <h3>Open Hours</h3>
                  <div className='font-sm mb-5'>
                    <p className='text-sm font-bold mb-3'>
                      We found multiple conflicting sources for your business hours.
                      <br /> Please fill these in below to make a clear source of truth.
                    </p>
                  </div>

                  {daysOfWeek.map((day) => (
                    <div key={day.short}>
                      <h4 className='mb-1'>{day.full}</h4>
                      <div className='flex flex-col w-fit items-center mb-4'>
                        {[...Array(numHours[day.short])].map((_, i) => (
                          <div className='mr-4' key={i}>
                            <EditOpenCloseHours
                              dayOfWeek={day.full}
                              dayOfWeekShort={`${day.short}_${i}`}
                            />
                          </div>
                        ))}
                        <button
                          onClick={() =>
                            setNumHours({ ...numHours, [day.short]: numHours[day.short] + 1 })
                          }
                          type='button'
                        >
                          <i className='fas fa-plus-circle px-8 text-fb_blue' />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  className={(formIsSubmittable ? 'opacity-100' : 'opacity-50') + ' button'}
                  type='submit'
                  disabled={!formIsSubmittable}
                >
                  Submit
                </button>
                {showSuccessMessage && !dirty && (
                  <div className='text-fb_primary mt-2'>
                    Changes saved. Edits may take up to 2 hours to display in our systems.
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
