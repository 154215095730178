import { createContext, useState } from 'react';

const GlobalInfoContext = createContext(null);

export default function GlobalInfoContextProvider({ children }) {
  const [globalRestos, setGlobalRestos] = useState({});

  const requestRestosForRestoIds = async (restoIds) => {
    // Request resto details for each id associated with this user
    const fullRestosByIdObject = {};
    for (let index in restoIds) {
      const restoId = restoIds[index];
      let resto = { gotError: true }; // default value, overwritten wherever get-resto-by-id succeeds
      try {
        const restoResponse = await fetch(`/.netlify/functions/get-resto-by-id?id=${restoId}`);
        if (restoResponse.ok) {
          resto = await restoResponse.json();
        }
      } catch (err) {
        console.error('Error while requesting resto info for resto with id', restoId);
        console.error(err);
      }

      // The object will contain either resto details or the default value of { gotError: true }, which UI elements can handle
      fullRestosByIdObject[restoId] = resto;
    }

    setGlobalRestos(fullRestosByIdObject);
  };

  return (
    <GlobalInfoContext.Provider value={{ globalRestos, setGlobalRestos, requestRestosForRestoIds }}>
      {children}
    </GlobalInfoContext.Provider>
  );
}

export { GlobalInfoContext };
