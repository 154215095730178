import { useState } from 'react';
import AdminNav from './components/Navbars/AdminNav.js';
import Footer from './components/Footers/Footer.js';
import Sidebar from './components/Sidebars/Sidebar.js';
import CardProfile from './components/Cards/CardProfile.js';
import { useAuth0 } from '@auth0/auth0-react';

export default function Profile() {
  // "user" object has namespaced (starts with site URL) details added by code that's configured in manage.auth0.com "Actions" section
  const { user } = useAuth0();
  const [successMessage, setSuccessMessage] = useState();

  const changePass = async () => {
    const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

    await fetch(`${auth0Domain}/dbconnections/change_password`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        email: user.email,
        connection: 'Username-Password-Authentication',
        client_id: auth0ClientId,
      }),
    })
      .then((response) => response)
      .then((data) => {
        console.log('success', data);
        setSuccessMessage('We received your request. Check your email to change your password.');
      })
      .catch((err) => {
        console.log('error', err.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    changePass();
  };

  return (
    <>
      <Sidebar />
      <div className='relative md:ml-64 bg-gray-100'>
        <AdminNav />
        <div className='p-2 md:p-8'>
          <h2>Profile</h2>
          <div className='grid grid-cols-5 items-start gap-5 pb-8 md:pb-32'>
            <div className='col-span-3'>
              <div className='card'>
                <h4>Account Settings</h4>
                <form onSubmit={handleSubmit}>
                  <div className='form-field'>
                    <label>Name</label>
                    <input type='text' placeholder={user.name} disabled />
                  </div>
                  <div className='form-field'>
                    <label>Email</label>
                    <input type='text' placeholder={user.email} disabled />
                  </div>
                  <div className='form-field'>
                    <button className='button button-small button-disabled mb-5' disabled>
                      Update Account
                    </button>
                  </div>
                  <hr></hr>
                  <p className='text-sm text-gray-600 mt-5'>
                    Need to change your password? <br></br>Click below to reset your password via
                    email.
                  </p>
                  <button
                    className='button button-hollow button-small button-secondary mt-5'
                    type='submit'
                  >
                    Change/Reset Your Password
                  </button>
                  {successMessage && <p className='text-red-500 pt-3'>{successMessage}</p>}
                </form>
              </div>
            </div>
            <div className='col-span-2'>
              <CardProfile />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
