import { isNil } from 'lodash';

export default function CardStats({
  dataReady = false,
  data,
  statKey,
  statName,
  statDescription,
  statIconName,
  statIconColor,
}) {
  let displayStat = 'Loading...';
  let displayArrow = 'loading';
  let displayPercent = '...';
  let displayPercentColor = 'text-black-300';

  if (dataReady && !isNil(data)) {
    const statForCurrentPeriod = data['currentPeriod'][statKey];
    const statForPreviousPeriod = data['previousPeriod'][statKey];

    displayStat = statForCurrentPeriod;

    // Determine if we show an up or down arrow. No change gets an up arrow right now
    let didStatIncreaseOrMaintain = statForCurrentPeriod >= statForPreviousPeriod;
    if (didStatIncreaseOrMaintain) {
      displayArrow = 'up';
      displayPercentColor = 'text-green-500';
    } else {
      displayArrow = 'down';
      displayPercentColor = 'text-red-500';
    }

    // Calculate the percent change from the previous period, starting with special cases when basis is 0
    if (statForPreviousPeriod == 0) {
      if (statForCurrentPeriod > 0) {
        displayPercent = '∞';
      } else {
        displayPercent = '0';
      }
    } else {
      const percentChange =
        Math.abs((statForCurrentPeriod - statForPreviousPeriod) / statForPreviousPeriod) * 100;
      displayPercent = percentChange.toFixed(0);
    }
  }

  return (
    <>
      <div className='relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg'>
        <div className='flex-auto p-4'>
          <div className='flex flex-wrap'>
            <div className='relative w-full pr-4 max-w-full flex-grow flex-1'>
              <h5 className='text-gray-500 uppercase font-bold text-xs'>{statName}</h5>
              <span className='font-semibold text-xl text-gray-800'>{displayStat}</span>
            </div>
            <div className='relative w-auto pl-4 flex-initial'>
              <div
                className={
                  'text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full ' +
                  statIconColor
                }
              >
                <i className={statIconName}></i>
              </div>
            </div>
          </div>
          <p className='text-sm text-gray-500 mt-4'>
            <span className={displayPercentColor + ' mr-2'}>
              <i
                className={
                  displayArrow === 'up'
                    ? 'fas fa-arrow-up'
                    : displayArrow === 'down'
                      ? 'fas fa-arrow-down'
                      : ''
                }
              ></i>{' '}
              {displayPercent}%
            </span>
            <span className='whitespace-no-wrap'>{statDescription}</span>
          </p>
        </div>
      </div>
    </>
  );
}
