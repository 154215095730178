import { useParams } from 'react-router-dom';

import AdminNav from './components/Navbars/AdminNav.js';
import EditRestaurantForm from './components/Forms/EditRestaurantForm.js';
import Footer from './components/Footers/Footer.js';
import Sidebar from './components/Sidebars/Sidebar.js';

export default function EditRestaurant() {
  let { restoId } = useParams();

  return (
    <>
      <Sidebar />
      <div className='relative md:ml-64 bg-gray-100'>
        <AdminNav />
        <EditRestaurantForm restoId={restoId} />
        <Footer />
      </div>
    </>
  );
}
